document.addEventListener('DOMContentLoaded', function () {
    console.debug('INFO canonical.js loaded');
    var canonicalUrl;
    var canonical = document.querySelector("link[rel='canonical']").getAttribute('href');
    var windowLocation = new URL(window.location.href);
    if (typeof canonical === 'undefined') {
        canonicalUrl = windowLocation;
    }
    else {
        canonicalUrl = new URL(canonical);
    }
    canonicalUrl.search = '';
    windowLocation.searchParams.forEach(function (value, key) {
        if (key != 'ncbp_transaction-id') {
            canonicalUrl.searchParams.append(key, value);
        }
    });
    document.querySelector("link[rel='canonical']").setAttribute('href', canonicalUrl.href);
});
